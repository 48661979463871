import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import Fade from 'react-reveal/Fade'
import he from 'he'

import SEO from '../components/seo'
import Subscribe from '../components/subscribe'
import Testimonials from '../components/testimonials'
import WorkCard from '../components/cards/work-card'
import PageCover from '../components/page-cover'

import { Communications } from '../components/icons'

class ServicesTemplate extends Component {

  renderIcon(param) {
    switch(param) {
      case 'communications':
        return <Communications colour="#000" />
      default:
        return <Communications colour="#000" />
    }
  }

  componentDidMount() {
    let { service } = this.props.data
    if (service.acf.color) {
      document.querySelector('.header').style.backgroundColor = service.acf.color
      document.querySelector('.header').style.borderBottomColor = service.acf.color
      document.querySelector('.header__services').style.backgroundColor = service.acf.color
      document.querySelector('.header__padding').style.backgroundColor = service.acf.color
      document.querySelector('.off-canvas').style.backgroundColor = service.acf.color
    }
  }

  componentDidUpdate() {
    let { service } = this.props.data
    if (service.acf.color) {
      document.querySelector('.header').style.backgroundColor = service.acf.color
      document.querySelector('.header').style.borderBottomColor = service.acf.color
      document.querySelector('.header__services').style.backgroundColor = service.acf.color
      document.querySelector('.header__padding').style.backgroundColor = service.acf.color
      document.querySelector('.off-canvas').style.backgroundColor = service.acf.color
    }
  }

  componentWillUnmount() {
    document.querySelector('.header').style.backgroundColor = ''
    document.querySelector('.header').style.borderBottomColor = ''
    document.querySelector('.header__services').style.backgroundColor = ''
    document.querySelector('.header__padding').style.backgroundColor = ''
    document.querySelector('.off-canvas').style.backgroundColor = ''
  }

  render() {
    let { service, workSector, workCategory } = this.props.data

    let work = workSector
		if (!work) work = workCategory || {edges: []}

		let customPosts = service.acf.posts_list
		let staticPosts = work

		if (customPosts) {
			staticPosts = ''
		} else {
			customPosts = ''
		}

    return <>
				<SEO title={service.title} bodyClass="services" />

				{service.acf.page_cover && <PageCover content={service.acf.page_cover} backgroundColor={service.acf.color} />}

				<div className="page-cover__under" style={{ backgroundColor: service.acf.color }}>
					<div className="container">
						<div className="services__inner">
							<Fade bottom distance="40px">
								<div className="col--1of4">
									<h4>{he.decode(service.title)}</h4>
									<div dangerouslySetInnerHTML={{ __html: service.content }} />
									<Link to="/contact" className="services__link">
										Talk to us
									</Link>
								</div>
							</Fade>
							<div className="col--2of3">
								{service.acf.content && service.acf.content.map((el, i) => {
										return <Fade bottom distance="40px" key={i}>
												<div className="services__content">
													<h4>{el.title}</h4>
													<div dangerouslySetInnerHTML={{ __html: el.content }} />
												</div>
											</Fade>
									})}
								<Fade bottom distance="40px">
									<div className="services__content">
										<Link to="/contact" className="services__link">
											Talk to us
										</Link>
									</div>
								</Fade>
							</div>
						</div>
					</div>

					{service.acf.testimonials && <Testimonials {...service.acf} />}

					{work.edges && <div className="services__work">
							<div className="container">
								<div className="services__inner">
									<Fade bottom distance="40px">
										<div className="col--1of3">
											<h4>Related work</h4>
										</div>
									</Fade>
									<div className="col--2of3">
										{customPosts && customPosts.map(
												(el, i) => {
													let props = {
														// image:  '',
														image:
															el.post.acf.featured_image &&
															el.post.acf.featured_image.localFile
																.childImageSharp.fixed.src,
														title: el.post.post_title,
														//link: el.post.guid,
														link: `/work/${el.post.post_name}`,
													}
													return <WorkCard key={i} {...props} />
												}
											)}

											{staticPosts && staticPosts.edges.map((el, i) =>  {
                        let props = {
                          image: el.node.acf.featured_image && el.node.acf.featured_image.localFile.childImageSharp.original.src,
                          title: el.node.title,
                          link: `/work/${el.node.slug}`,
                        }
                        return <WorkCard key={i} {...props} />
                      }
											)}
									</div>
								</div>
							</div>
						</div>}

					<Subscribe />
				</div>
			</>
  }
}

export default ServicesTemplate

export const postQuery = graphql`
  query ($category: String!) {
    service: wordpressWpService(slug: {eq: $category}) {
      title
      content
      slug
      acf {
        color
        icon
        page_cover
        content {
          title
          content
				}
				posts_list {
					post {
						post_title
						wordpress_id
						post_author
						post_date
						post_date_gmt
						post_content
						post_title
						post_excerpt
						post_status
						comment_status
						ping_status
						post_password
						post_name
						to_ping
						pinged
						post_modified
						post_modified_gmt
						post_content_filtered
						post_parent
						guid
						menu_order
						post_type
						post_mime_type
						comment_count
						filter
						acf {
							featured_image {
								localFile {
									childImageSharp {
										fixed(width: 1200) {
											src
										}
									}
								}
							}
						}
					}
				}
			testimonials {
				post_title
				acf {
					logo {
						localFile {
							childImageSharp {
								original {
									src
								}
							}
							}
						}
						quote
						staff_member
						position
					}
				}
			}
		}
    workCategory: allWordpressWpWork(
      filter: { categories: { elemMatch: {slug: { eq: "communications" } } }},
      limit: 4
    ) {
      edges {
        node {
          title
          slug
          acf {
            featured_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    workSector: allWordpressWpWork(
      filter: { sector: { elemMatch: {slug: { eq: "arts" } } }},
      limit: 4
    ) {
      edges {
        node {
          title
          slug
          acf {
            featured_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
