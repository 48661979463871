import React, { Component } from 'react'
//import addToMailchimp from 'gatsby-plugin-mailchimp'
import { FormButton } from './icons'
import Recaptcha from 'react-google-recaptcha'

class Subscribe extends Component {
	recaptcha_key = '6Le_V7cUAAAAAIyPUvyR7X8kqV7sqORyKFKkVRE0'
	_postURL =
		'https://wordpress.ellisjones.com.au/processor/campaignmonitor.php'

	state = {
		result: '',
		msg: '',
	}

	handleRecaptcha = value => {
		this.setState({ 'g-recaptcha-response': value })
	}

	_handleChange = e => {
		this.setState({
			[`${e.target.name}`]: e.target.value,
		})
	}

	async _addToEJList(email, additional) {
		let fields = {
			email: email,
			first_name: additional.FNAME,
			last_name: additional.LNAME,
		}
		let post_url = this._postURL
		let body = JSON.stringify(fields)

		let data_res = await fetch(post_url, {
			method: 'post',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
			},
			body: body,
		})
		let data = await data_res.json()
		this.setState({
			result: data.result,
			msg: data.msg,
			//res.json()
		})
	}

	// 2. via `async/await`
	_handleSubmit = async e => {
		e.preventDefault()
		await this._addToEJList(this.state.email, {
			FNAME: this.state.fname,
			LNAME: this.state.lname,
		})
		// I recommend setting `result` to React state
		// but you can do whatever you want
		/*this.setState({
       result: result.result,
       msg: result.msg
       })*/
	}

	render() {
		return (
			<section className="subscribe">
				<div className="subscribe__inner container">
					<div className="col col--1of3">
						<h2>Subscribe to our newsletter</h2>
					</div>
					<div className="col col--2of3 subscribe__form">
						<form onSubmit={this._handleSubmit}>
							<div className="left">
								<input
									className="fname"
									type="text"
									onChange={this._handleChange}
									placeholder="First Name"
									name="fname"
								/>
								<input
									className="lname"
									type="text"
									onChange={this._handleChange}
									placeholder="Last Name"
									name="lname"
								/>
								<input
									className="email"
									type="email"
									onChange={this._handleChange}
									placeholder="Email Address"
									name="email"
								/>

								<div className="recaptcha">
									<Recaptcha
										ref="recaptcha"
										sitekey={this.recaptcha_key}
										onChange={this.handleRecaptcha}
									/>
								</div>
							</div>
							<div className="right">
								<div className="wrap">
									<FormButton colour="#FC4C02" />
									<input
										className="submit"
										type="submit"
										value="Submit"
									/>
								</div>
							</div>
						</form>
						{this.state.result !== '' && (
							<div className="form__form-wrapper">
								<p
									className="result"
									dangerouslySetInnerHTML={{
										__html: this.state.msg,
									}}
								/>
							</div>
						)}
					</div>
				</div>
			</section>
		)
	}
}

export default Subscribe
