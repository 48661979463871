import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

class WorkCard extends Component {
  render() {
		let { image, title, link } = this.props

    return (
      <Fade>
        <div className="card card--work">
          <Link to={link}>
            <figure>
              { image && <img src={image} alt={title} /> }
            </figure>
            <h4><span>{title}</span></h4>
          </Link>
        </div>
      </Fade>
    )
  }
}


export default WorkCard
