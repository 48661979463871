import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import left from '../assets/images/arrow-left-alt.svg'
import right from '../assets/images/arrow-right-alt.svg'

// Component to fix beforeChange bug rerendering slick
class BlockUpdate extends Component {
  shouldComponentUpdate() {
    return false
  }  
  render() {
    return (<>{ this.props.children }</>)
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={right}
      alt='next gallery item'
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={left}
      alt='last gallery item'
      onClick={onClick}
    />
  );
}

class Testimonials extends Component {

  state = {
    activeSlide: 0,
  }

  render() {
    const settings = {
      infinite: true,
      speed: 600,
      fade:true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }
    return (
      <section className='title-content title-content--dark title-content--testimonials'>
        <Fade bottom distance='40px'>
          <div className="title-content__inner container">
            <div className="col col--1of3">
              <h2>What our clients say</h2>
            </div>
            <div className="col col--2of3">
              <BlockUpdate>
                <Slider className="testimonials" {...settings}>
                  { this.props.testimonials.map((el, i) => {
                    let logo = el.acf.logo.localFile && el.acf.logo.localFile.childImageSharp.original.src
                    return (
                      <div className="testimonials__item" key={i}>
                        <div className="team__content">
                          <img className="testimonials__logo" src={logo} alt={el.post_title} />
                          <div className="title-content__content testimonials__quote" dangerouslySetInnerHTML={{ __html: el.acf.quote }} />
                          <div className="testimonials__staff">
                            <p>— {el.acf.staff_member}, {el.post_title}</p>
                          </div>
                        </div>
                      </div>
                    )
                  }) }
                </Slider>
              </BlockUpdate>
            </div>
          </div>
        </Fade>
      </section>
    )
  }

}

export default Testimonials
